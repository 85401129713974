<template>
  <div v-if="activeBooking" class="booking-details-summary-wrapper">
    <div
      v-if="activeBookingDetails.departureFlight"
      :data-first="true"
      class="booking-details-summary-content-line"
    >
      <AppDashedLine direction="vertical" />
      <div></div>
    </div>
    <div
      v-if="activeBookingDetails.departureFlight"
      class="booking-details-summary-content"
    >
      <AppLabel
        label="DEPARTURE FLIGHT"
        :label-right="activeBookingDetails.departureFlight.date"
      />
      <BookingFlightCardSummary
        :booking="activeBookingDetails.booking"
        :direction="activeBookingDetails.departureFlight.direction"
        info-label="Flight reservation details"
        show-info
        show-card
        @click="goToFlight('departure')"
      />
    </div>
    <div
      v-if="activeBookingDetails.hotel"
      :data-only="!activeBookingDetails.hasFlight"
      class="booking-details-summary-content-line"
    >
      <AppDashedLine direction="vertical" />
      <div></div>
    </div>
    <div
      v-if="activeBookingDetails.hotel"
      class="booking-details-summary-content"
    >
      <AppLabel
        label="ACCOMODATION"
        :label-right="activeBookingDetails.hotel.date"
      />
      <BookingHotelCardSummary
        :booking="activeBookingDetails.booking"
        :hotel="activeBookingDetails.hotel.data"
        info-label="Hotel reservation details"
        show-info
        show-card
        @click="goToHotel"
      />
    </div>
    <div
      v-if="activeBookingDetails.returnFlight"
      :data-last="true"
      class="booking-details-summary-content-line"
    >
      <AppDashedLine direction="vertical" />
      <div></div>
    </div>
    <div
      v-if="activeBookingDetails.returnFlight"
      class="booking-details-summary-content"
    >
      <AppLabel
        label="RETURN FLIGHT"
        :label-right="activeBookingDetails.returnFlight.date"
      />
      <BookingFlightCardSummary
        :booking="activeBookingDetails.booking"
        :direction="activeBookingDetails.returnFlight.direction"
        info-label="Flight reservation details"
        show-info
        show-card
        @click="goToFlight('return')"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import AppLabel from '../../components-rf/AppLabel.vue'
import BookingFlightCardSummary from '../../components-rf/bookings/BookingFlightCardSummary.vue'
import BookingHotelCardSummary from '../../components-rf/bookings/BookingHotelCardSummary.vue'
import AppDashedLine from '../../components-rf/AppDashedLine.vue'

export default {
  name: 'BookingsDetailsSummary',
  components: {
    BookingFlightCardSummary,
    AppLabel,
    BookingHotelCardSummary,
    AppDashedLine,
  },
  computed: {
    ...mapState('BookingModule', ['activeBooking']),
    ...mapGetters('BookingModule', ['activeBookingDetails']),
  },
  methods: {
    goToFlight(type) {
      this.$router.push(
        `/bookings/details/flight/${type}?eluderef=${this.$route.query.eluderef}`,
      )
    },
    goToHotel() {
      this.$router.push(
        `/bookings/details/hotel?eluderef=${this.$route.query.eluderef}`,
      )
    },
  },
}
</script>

<style scoped lang="scss">
.booking-details-summary-wrapper {
  display: grid;
  grid-template-columns: auto 1fr;
  & > *:nth-child(2n + 4) {
    margin-top: 1rem;
  }
}
.booking-details-summary-content {
  display: grid;
  gap: 0.5rem;
}
.booking-details-summary-content-line {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 1rem;
  & > div:first-child {
    height: 100%;
    width: 2px;
    justify-self: center;
  }
  & > div:last-child {
    position: absolute;
    height: 18px;
    width: 18px;
    background: inherit;
    background: var(--color-light);
    border: 1px solid grey;
    border-radius: 50%;
  }
}
.booking-details-summary-content-line[data-only='true'] {
  & > div:first-child {
    display: none;
  }
}
.booking-details-summary-content-line[data-first='true'] {
  padding-top: 0.5rem;
  & > div:last-child {
    align-self: flex-start;
  }
}
.booking-details-summary-content-line[data-last='true'] {
  padding-bottom: 0.5rem;
  & > div:last-child {
    align-self: flex-end;
  }
}
</style>
