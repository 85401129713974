<template>
  <div
    :class="{ 'app-card-clickable': showCard }"
    class="flight-card"
    tabindex="0"
    @click="$emit('click')"
    @keyup.enter="$emit('click')"
  >
    <p class="flight-card-airport">
      <span style="display: inline-block"
        >{{ display.departureAirport }}&nbsp;</span
      >
      <span style="display: inline-block">to {{ display.arrivalAirport }}</span>
    </p>
    <div class="flight-card-main">
      <AppThumbnailWithPlaceholder
        :src="display.airline.media.imageUrl"
        placeholder="airline"
      />
      <div class="flight-card-text">
        <p style="font-family: LuciferSans; font-size: 15px">
          {{ display.departureTime }} - {{ display.arrivalTime }}
        </p>
        <p>{{ display.airline.name }} - {{ display.flightCode }}</p>
        <p>Flight PNR: {{ booking.airlineReservationCode }}</p>
      </div>
    </div>
    <template v-if="showInfo">
      <AppDashedLine />
      <AppCardInfo
        :label="infoLabel"
        :price="infoPrice"
        class="app-card-info"
      />
    </template>
  </div>
</template>

<script>
import { date } from '../../other/utils/helpers/date'
import AppCardInfo from '../AppCardInfo.vue'
import AppDashedLine from '../AppDashedLine.vue'
import AppThumbnailWithPlaceholder from '../AppThumbnailWithPlaceholder.vue'

const formatSegmentDuration = date.prettyDuration('-dd -hhr -min2min')
const formatSegmentDate = timestamp =>
  date.formatUTC('-ml -d · -h2:-min2', timestamp, true)
const formatTime = timestamp => date.formatUTC('-h2:-min2', timestamp, true)

export default {
  name: 'BookingFlightCardSummary',
  components: {
    AppCardInfo,
    AppDashedLine,
    AppThumbnailWithPlaceholder,
  },
  props: {
    booking: Object,
    direction: Object,
    infoPrice: Number,
    infoLabel: String,
    showInfo: Boolean,
    showCard: Boolean,
  },
  computed: {
    display() {
      const { segmentsList: segments } = this.direction
      const first = segments.at(0)
      const last = segments.at(-1)

      return {
        departureAirport: first.departureAirport?.name,
        arrivalAirport: last.arrivalAirport?.name,
        airline: first.airline,
        departureTime: formatTime(first.departureDateTime),
        departureUtcOffset: first.departureUtcOffset,
        arrivalTime: formatTime(last.arrivalDateTime),
        arrivalUtcOffset: first.arrivalUtcOffset,
        flightCode: first.airline.iataCode + first.flightNumber,
      }
    },
  },
  methods: {
    isFirst(index) {
      return index === 0
    },
    isLast(index) {
      return index === this.display.segments.length - 1
    },
    formatSegmentDuration,
    formatSegmentDate,
  },
}
</script>

<style scoped lang="scss">
.flight-card {
  display: grid;
  gap: 1rem;
}
@media (hover: hover) {
  .flight-card:hover .app-card-info {
    --box-shadow-height: 2px;
  }
}
.flight-card-airport {
  font-size: 15px;
  font-family: DDin;
  line-height: 1.3;
}
.flight-card-main {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1rem;
  line-height: 1.5;
}
.flight-card-text {
  display: grid;
  line-height: 1.2;
  font-size: 13px;
  font-family: DDin;
}
</style>
