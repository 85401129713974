<template>
  <div class="app-card-info">
    <p class="app-card-info-button">{{ label }}</p>
    <div v-if="price !== undefined" class="app-card-info-price">
      <p>Total</p>
      <p>{{ $formatPrice(price) }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppCardInfo',
  props: {
    label: String,
    price: Number,
  },
}
</script>

<style scoped lang="scss">
.app-card-info {
  display: flex;
  align-items: center;
  min-height: 30px;
  --box-shadow-height: 1px;
}
.app-card-info-button {
  font-size: 15px;
  font-family: DDin;
  box-shadow: 0 var(--box-shadow-height) var(--color-secondary);
  line-height: 1.2;
  transition: box-shadow 0.2s ease-in-out;
}
.app-card-info-price {
  margin-left: auto;
  display: flex;
  align-items: center;
  & > p:first-child {
    font-family: LuciferSans;
    font-size: 13px;
    line-height: 1.2;
    text-align: end;
  }
  & > p:last-child {
    font-family: SpaceMono;
    font-size: 25px;
    margin-left: 10px;
  }
}
</style>
